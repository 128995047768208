import { toast } from "vue3-toastify";
export default {
    namespaced: true,
    state() {
        return {
            clinicianList: [],
            clinicianDetails: {},
            specialisations: [],
            availibility: [],
            appointments: [],
            associatedClinics: [],
            cliniciansListDetails: [],
            outlookEvents: [],
            careRecords: [],
        };
    },
    mutations: {
        setClinicianList(state, payload) {
            state.clinicianList = payload;
        },
        setClinicianDetails(state, payload) {
            state.clinicianDetails = payload;
        },
        setSpecialisations(state, payload) {
            state.specialisations = payload;
        },
        setAvailibility(state, payload) {
            state.availibility = payload;
        },
        setAppointments(state, payload) {
            state.appointments = payload;
        },
        setOutlookEvents(state, payload) {
            state.outlookEvents = payload;
        },
        setAssociatedClinics(state, payload) {
            state.associatedClinics = payload;
        },
        setCliniciansListDetails(state, payload) {
            state.cliniciansListDetails = payload;
        },
        setCareRecords(state, payload) {
            state.careRecords = payload;
        },
    },
    getters: {
        clinicianList(state) {
            return state.clinicianList;
        },
        clinicianDetails(state) {
            return state.clinicianDetails;
        },
        specialisations(state) {
            return state.specialisations;
        },
        availibility(state) {
            return state.availibility;
        },
        appointments(state) {
            return state.appointments;
        },
        outlookEvents(state) {
            return state.outlookEvents;
        },
        associatedClinics(state) {
            return state.associatedClinics;
        },
        cliniciansListDetails(state) {
            return state.cliniciansListDetails;
        },
        careRecords(state) {
            return state.careRecords;
        },
    },
    actions: {
        setToken({ commit }, payload) {
            commit("setToken", payload);
        },
        async setAssociatedClinics({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinics?clinicianId=" +
                        payload,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit("setAssociatedClinics", response);
            } catch (error) {
                console.log(error, "error");
            }
        },
        async editAvailibillity({ _, rootState, dispatch }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "PUT",
                    body: JSON.stringify(payload),
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/edit",
                    requestOptions
                ).then(async (response) => {
                    if (response.status === 200) {
                        dispatch("setAvailibility", payload.payloadForDispatch);
                        toast.success("Availability successfully edited");
                    }
                    if (response.status === 409) {
                        toast.error(
                            "This slot conflicts with another Availability slot"
                        );
                    }
                    if (response.status === 400) {
                        const resp = await response.json();
                        if (resp?.RecurrenceCount) {
                            toast.error(resp?.RecurrenceCount?.[0]);
                        }
                        if (resp?.EndDate) {
                            toast.error(resp?.EndDate?.[0]);
                        }
                        if (resp?.ClinicId) {
                            toast.error(resp?.ClinicId?.[0]);
                        }
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                console.log(error, "error", _);
            }
        },
        async deleteAvailibillity({ _, rootState, dispatch }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "DELETE",
                    body: JSON.stringify(payload),
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/delete",
                    requestOptions
                ).then((response) => {
                    if (response.status === 200) {
                        dispatch("setAvailibility", payload.payloadForDispatch);
                        toast.success("Availability successfully deleted");
                    }
                    if (response.status === 409) {
                        return response.status;
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                console.log(error, "error", _);
            }
        },
        async addAvailibillity({ _, rootState, dispatch }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    body: JSON.stringify({
                        ...payload,
                        clinicId: rootState.clinicians.associatedClinics.find(
                            (clinic) => clinic.name === payload.clinicId
                        )?.codedId,
                    }),
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/add",
                    requestOptions
                ).then(async (response) => {
                    if (response.status === 200) {
                        dispatch("setAvailibility", payload.payloadForDispatch);
                        toast.success(
                            "New availability slot successfully added"
                        );
                    }
                    if (response.status === 409) {
                        toast.error(
                            "This slot conflicts with another Availability slot"
                        );
                    }
                    if (response.status === 400) {
                        const resp = await response.json();
                        if (resp?.RecurrenceCount) {
                            toast.error(resp?.RecurrenceCount?.[0]);
                        }
                        if (resp?.EndDate) {
                            toast.error(resp?.EndDate?.[0]);
                        }
                        if (resp?.ClinicId) {
                            toast.error(resp?.ClinicId?.[0]);
                        }
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                console.log(error, "error", _);
                toast.error("Availability slot has not been added");
            }
        },
        async setAppointments({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    `${process.env.VUE_APP_BACKEND_BASE_URL}api/clinicians/appointments/${payload?.clinicianId}?StartDate=${payload?.startDate}&EndDate=${payload?.endDate}`,
                    requestOptions
                ).then((response) => {
                    if (response.status === 500 || response.status === 400) {
                        return [];
                    }
                    return response.status === 204 ? [] : response.json();
                });
                commit("setAppointments", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setAvailibility({ commit, rootState, dispatch }, payload) {
            dispatch("setAppointments", payload);
            dispatch("setOutlookEvents", payload);
            dispatch("setClinicianDetailsSchedule", payload?.clinicianId);
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    `${process.env.VUE_APP_BACKEND_BASE_URL}api/availability/${payload?.clinicianId}?StartDate=${payload?.startDate}&EndDate=${payload?.endDate}`,
                    requestOptions
                ).then((response) => {
                    if (response.status === 500 || response.status === 400) {
                        return [];
                    }
                    return response.status === 204 ? [] : response.json();
                });
                commit("setAvailibility", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setOutlookEvents({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    `${process.env.VUE_APP_BACKEND_BASE_URL}api/clinicians/outlookevents`,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit("setOutlookEvents", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setSpecialisations({ commit, rootState }) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/specialisations?SortDescending=false",
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit(
                    "setSpecialisations",
                    response?.map((data) => data.name)
                );
            } catch (error) {
                console.log(error);
            }
        },
        async setClinicianList({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL + "api/clinicians",
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit("setClinicianList", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setClinicianDetails({ commit, rootState }, payload) {
            localStorage.setItem("clinicianId", payload);
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/details/" +
                        payload,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? {} : response.json()
                );
                if (response) {
                    try {
                        const responseNotes = await fetch(
                            process.env.VUE_APP_BACKEND_BASE_URL +
                                "api/clinicians/notes/" +
                                payload,
                            requestOptions
                        ).then((response) =>
                            response.status === 204 ? [] : response.json()
                        );
                        if (responseNotes) {
                            try {
                                const responseClinics = await fetch(
                                    process.env.VUE_APP_BACKEND_BASE_URL +
                                        "api/clinics?clinicianId=" +
                                        payload,
                                    requestOptions
                                ).then((response) =>
                                    response.status === 204
                                        ? []
                                        : response.json()
                                );
                                response.specialisations =
                                    response.specialisations.sort();
                                commit("setClinicianDetails", {
                                    ...response,
                                    notes: responseNotes,
                                    clinics: responseClinics,
                                });
                            } catch (error) {
                                commit("setClinicianDetails", {
                                    ...response,
                                });
                                console.log(error, "error");
                            }
                        }
                    } catch (error) {
                        commit("setClinicianDetails", { ...response });
                        console.log(error, "error");
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async updateClinicianDetails({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/details/" +
                        payload,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? {} : response.json()
                );

                if (response) {
                    commit("setClinicianDetails", {
                        ...response,
                    });
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async setClinicianDetailsSchedule({ commit, rootState }, payload) {
            localStorage.setItem("clinicianId", payload);
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/details/" +
                        payload,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? {} : response.json()
                );
                if (response) {
                    try {
                      commit("setClinicianDetails", response);
                    } catch (error) {
                        console.log(error, "error");
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async updateProfileDetails({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/profile",
                    requestOptions
                ).then(async (response) =>
                    response.status === 400
                        ? { error: await response.json() }
                        : response.json()
                );
                if (response.error) {
                    toast.error(response.error?.ClinicianType?.[0]);
                } else {
                    commit("setClinicianDetails", {
                        ...rootState.clinicians.clinicianDetails,
                        ...response,
                    });
                    toast.success("Changes have been saved successfully");
                }
            } catch (error) {
                toast.error("Your changes have not been saved");
                console.log(error, "error");
            }
        },
        async cliniciansListDetails({ commit, rootState }) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        maxNumberOfClinicians: 10,
                        searchByName: "",
                        sortDescendingBySurname: false,
                    }),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/list",
                    requestOptions
                ).then(async (response) =>
                    response.status === 204 ? [] : response.json()
                );
                if (response) {
                    commit("setCliniciansListDetails", response);
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async registerClinicianAccount(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        clinicianId: payload,
                    }),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/networkclinicians/register",
                    requestOptions
                ).then(async (response) => {
                    return response.json();
                });
                return response;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async resendInvite(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        clinicianId: payload,
                    }),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/networkclinicians/reactivate",
                    requestOptions
                ).then(async (response) => response.json());
                return response;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async deactivateClinicianAccount(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/networkclinicians/deactivate",
                    requestOptions
                ).then(async (response) => response.json());
                return response;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async setCareRecords(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/caseaccess/record-of-care",
                    requestOptions
                ).then(async (response) =>
                    response.status === 200 ? response.json() : []
                );
                props.commit("setCareRecords", response);
            } catch (error) {
                console.log(error, "error");
            }
        },
        async grantCareRecordsAccess(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/caseaccess/record-of-care/grant",
                    requestOptions
                ).then(async (response) =>
                    response.status
                );
                return response;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async blockCareRecordsAccess(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/caseaccess/record-of-care/block",
                    requestOptions
                ).then(async (response) =>
                    response.status
                );
                return response;
            } catch (error) {
                console.log(error, "error");
            }
        },
        async checkSelfManageAvailability(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(payload),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/self-managed-availability/get",
                    requestOptions
                ).then(async (response) =>
                    response.json()
                );
                return response?.isSelfManaged;
            } catch (error) {
                console.log(error, "error");
            }
        },
    },
};

